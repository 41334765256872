.containerProducts {
  width: 100%;
  display: flex;
  align-items: start;
  flex-wrap: wrap;
}

.filterContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 5px;
  padding-top: 5px;
}

.filterButton {
  width: 15%;
}

.filterDisplay {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0px 0px 5px 0px;
}
.filterDisplayButtons {
  display: flex;
  justify-content: space-around;
  margin: 10px 0px 10px 0px;
}
