.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  padding: 1.5rem 1rem;

  @media (min-width: 1024px) {
    height: 100vh;
    overflow: hidden;
  }
}

@media (min-width: 1024px) {
  .content {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  }
}

.headerDetails {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  margin-bottom: 1rem;
  padding: 1.25rem 0;
  border-radius: 0.35rem;

  h1 {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.5rem;
    color: var(--black-700);
    display: flex;
    align-items: center;

    p {
      font-family: "Montserrat";
      font-weight: 700;
      font-size: 1.5rem;
      color: var(--black-700);
    }
  }
}

.containerDetails {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  background-color: var(--white);
  border-radius: 0.35rem;
  padding: 2.5rem 3rem;
}

.contentImages {
  width: 40%;
  padding: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  row-gap: 0;
  column-gap: 0;

  .imgProduct {
    width: 97%;
    height: 97%;
    padding: 5px;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 25px;
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .imgProductMini {
    width: 6.8rem;
    height: 6.8rem;
    cursor: pointer;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 25px;
    margin-right: 1rem;
    background-color: var(--orange-700);
  }
}

.contentDetails {
  width: 60%;
  padding: 1rem;
}

.titleProduct {
  margin-bottom: 1rem;
  h1 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 2.25rem;
    color: var(--black-700);
    margin-bottom: 0.5rem;
  }

  div {
    display: flex;

    span {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1.25rem;
      color: var(--gray-300);
      margin-right: 1rem;
    }

    p {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1.25rem;
      color: var(--orange-700);
    }
  }
}

.contentDescription {
  margin-bottom: 2rem;
  h1 {
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 1.75rem;
    color: var(--black-700);
    margin-bottom: 0.5rem;
  }

  p {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 0.75rem;
    color: var(--gray-300);
  }
}

.contentForm {
  margin-top: 1rem;
}

.boxInputs {
  margin-top: 1rem;

  .formLine1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  .formLine2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }
}

.boxFinalPrice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 0;

  p {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.25rem;
    color: var(--gray-300);
    margin-right: 1rem;
  }

  span {
    &:nth-child(2) {
      font-family: "Montserrat";
      font-weight: 500;
      font-size: 1.25rem;
      color: var(--orange-700);
      margin-right: 1rem;
    }

    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1rem;
    color: var(--gray-200);
  }
}

@media (max-width: 1024px) {
  .containerDetails {
    padding: 1.5rem;
  }

  .boxInputs {
    &:nth-child(3) {
      margin-top: 0;
      .formLine1 {
        flex-wrap: wrap;
      }
      .formLine2 {
        flex-wrap: wrap;
        align-items: flex-start;
      }
    }
  }

  .boxFinalPrice {
    p {
      text-wrap: nowrap;
      font-size: 1rem;
    }

    span {
      text-wrap: nowrap;
      &:nth-child(2) {
        font-size: 1rem;
      }

      font-size: 0.75rem;
    }
  }
}

@media (max-width: 768px) {
  .containerDetails {
    flex-direction: column;
    padding: 1.5rem;
    align-items: center;
    justify-content: center;
  }

  .contentDetails {
    width: 80%;
  }

  .container {
    width: 100%;
    align-self: center;
  }

  .content {
    width: 100%;
  }

  .contentImages {
    width: 60%;
  }

  .titleProduct {
    h1 {
      font-size: 1.5rem;
    }

    div {
      span {
        font-size: 1rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }

  .contentImages {
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;

    .imgProduct {
      grid-column-start: 1;
      grid-column-end: 5;
      width: 100%;
    }

    .imgProductMini {
      width: 6.5rem;
      height: 6.5rem;
    }
  }

  .boxFinalPrice {
    flex-wrap: wrap;
    p {
      text-wrap: wrap;
      font-size: 1rem;
    }

    span {
      text-wrap: wrap;
      &:nth-child(2) {
        font-size: 1rem;
      }

      font-size: 0.75rem;
    }
  }

  .titleProduct {
    h1 {
      font-size: 2rem;
    }

    div {
      span {
        font-size: 1rem;
      }

      p {
        font-size: 1rem;
      }
    }
  }

  .contentDescription {
    h1 {
      font-size: 1.5rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .formLine1 {
    flex-wrap: wrap;
  }

  .formLine2 {
    flex-wrap: wrap;
    align-items: flex-start;
  }
}

.labelShipping {
  p {
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1rem;
    color: var(--black-700) !important;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
}
