.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;

  .button {
    width: 580px;
    margin-top: 10%;
    height: 45px;
    background-color: var(--orange-700);
    border-radius: 0.35rem;
    border: none;
    outline: none;
    color: var(--white);
    cursor: pointer;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1.3rem;
  }
  .upload {
    width: 580px;
    height: 100px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 3px dotted var(--orange-700);
    border-radius: 8px;
  }
}
