.inputSelect {
    width: 100%;
    height: 40px;
    border-radius: 4px;
    border: none;
    padding: 0 10px;
    font-size: 14px;
    color: #333;
    background-color: var(--gray-100);
    transition: all 0.3s ease;
    cursor: pointer;
}