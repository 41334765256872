.contentProduct {
  background-color: var(--white);
  width: 100%;
  max-width: 25rem;
  height: auto; /* Remove a altura fixa para permitir que o conteúdo determine a altura */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1.5rem;
  border-radius: 0.35rem;
  box-sizing: border-box;
  margin: 1rem auto;
}

.boxHeaderProduct {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 20px;
  margin: 20px;
}

h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.2rem; /* Aumenta o tamanho da fonte em telas maiores */
  margin-bottom: 10px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}

.boxValue {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.boxValue p {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1rem; /* Aumenta o tamanho da fonte para valores */
  color: var(--gray-300);
  margin: 0.25rem 0;
  flex: none;
}

.boxValue p:nth-of-type(1),
.boxValue p:nth-of-type(3) {
  color: var(--orange-700);
}

.boxImage {
  width: 250px;
  height: 180px;
  padding: 5px;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.boxLink {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  cursor: pointer;
}

svg {
  color: var(--gray-200);
  font-size: 1.2rem; /* Aumenta o tamanho do ícone em telas maiores */
  margin-right: 8px;
}

p {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 1.2rem; /* Aumenta o tamanho da fonte do link em telas maiores */
  color: var(--gray-200);
  margin: 0;
}

.boxLink:hover svg {
  color: var(--orange-700);
}

.boxLink:hover p {
  color: var(--orange-700);
}

/* Ajustes para telas menores */
@media (max-width: 800px) {
  .contentProduct {
    max-width: 100vw;
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .boxHeaderProduct h4 {
    font-size: 0.9rem;
  }

  .boxValue {
    width: 90%;
    height: auto;
    margin-bottom: 1px;
  }

  .boxValue p {
    font-size: 0.8rem;
    margin: 0.25rem 0;
  }

  .boxImage img {
    max-width: 180px;
    max-height: 130px;
  }

  .boxLink svg {
    font-size: 0.9rem;
    margin-right: 5px;
  }

  .boxLink p {
    font-size: 1rem;
  }
}

/* Ajustes para telas maiores */
@media (min-width: 1200px) {
  .contentProduct {
    max-width: 20rem;
    height: 30rem;
    padding: 2rem;
  }

  .boxHeaderProduct h4 {
    font-size: 14px;
    padding: 5px;
  }

  .boxValue p {
    font-size: 12px;
  }

  .boxImage {
    height: 200px;
  }

  .boxLink svg {
    font-size: 1.4rem;
  }

  .boxLink p {
    font-size: 20px;
  }
}
