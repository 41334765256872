.container {
  background-color: var(--white);
  padding: 1rem;
  border-radius: 0.25rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  font-weight: 600;
}

.icon {
  color: var(--gray-300);
  cursor: pointer;
  &:hover {
    color: var(--orange-700);
  }
}

.button {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.form {
  margin-top: 1rem;
}

.body {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;
}

.label {
  margin-left: 0.1rem;
  align-self: flex-start;
}

.labelWithIcon {
  display: flex;
  gap: 0.5rem;
  margin-left: 0.1rem;
  align-items: center;
  cursor: text;
  align-self: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.checkbox {
  margin-left: 0.5rem;
}

.tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}

.boxCademi {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    &:first-child {
      font-size: 1.5rem;
      color: var(--gray-200);
    }
  }
}

.checkbox {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  color: var(--gray-200);
}

.optionContainer {
  display: flex;
  flex-direction: row;
}
.optionContainerChild {
  display: flex;
  flex-direction: column;
}

.addButton {
  width: 1%;
  border: 1px solid;
  background-color: #fff;
  color: #000;
  border-block-color: #000;
}