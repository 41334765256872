.container {
  padding-top: 6.2rem;
}

.buttonChat {
  position: fixed;
  right: 15px;
  bottom: 20px;
  height: 60px;
  width: 60px;
  background-color: #f04e23;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  background-image: url("../../assets/imgs/chatboxes.png");
  background-repeat: no-repeat;
  background-position: center;
}

.content {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  padding-right: 2.5rem;
  flex: 1;
  margin-left: 18.3rem;
}

.scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media only screen and (max-width: 768px) {
  .content {
    margin-left: 1.5rem;
  }
}
