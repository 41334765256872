.dashBoardPage {
  margin-bottom: 20px;
  border-radius: 10px;

  .container {
    padding: 16px;
    background-color: #f0f0f0;
  }

  .cardsRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5%;
  }
  .buttons {
    display: flex;
    justify-content: flex-start;
  }

  .charts {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }

  .barChartContainer {
    flex-grow: 1;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    height: 350px;
    margin: 10px;
  }
  .icon {
    color: var(--gray-300);
    cursor: pointer;
    &:hover {
      color: var(--orange-700);
    }
  }
  .pieChartContainer {
    flex-grow: 1;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    height: 350px;
    margin: 10px;
  }

  .lineChartContainer {
    flex-grow: 1;
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    height: 350px;
    margin: 10px;
    padding: 2px;
  }

  .processButton {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1rem;
    font-weight: bold;
    color: #f04e23;
    background-color: transparent;

    span {
      padding-left: 1rem;
    }
  }
}

@media (max-width: 600px) {
  .dashBoardPage {
    grid-template-rows: 350px 220px 269px;
  }
}

@media (max-width: 1000px) {
  .dashBoardPage {
    grid-template-rows: 370px 220px 269px;
    .container {
      width: 85vw;
    }
    .barChartContainer {
      width: 85vw;
      height: 250px;
    }
  }
}
