.dashBoardPage {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4.7px);
  -webkit-backdrop-filter: blur(4.7px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;

  .textInfo {
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 22px;
    padding: 5px;
  }

  .title {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  .container {
    width: 100%;
  }

  .cardsRow {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 5%;
  }
  .buttons {
    display: flex;
    justify-content: flex-start;
  }

  .charts {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .barChartContainer {
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 550px;
    height: 300px;
  }
  .icon {
    color: var(--gray-300);
    cursor: pointer;
    &:hover {
      color: var(--orange-700);
    }
  }
  .pieChartContainer {
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 450px;
    height: 300px;
  }

  .lineChartContainer {
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
    padding: 16px;
    width: 450px;
    height: 300px;
  }

  .processButton {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 1rem;
    font-weight: bold;
    color: #f04e23;
    background-color: transparent;

    span {
      padding-left: 1rem;
    }
  }
}
