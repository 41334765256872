* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

//TODO: A estilização do collapsble não funcionar nos arquivos .module.scss por isso coloquei aqui
.ant-collapse {
  border: none;
  width: 100%;
  background: transparent;
  max-width: 1200px;
}

.ant-collapse-header {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  padding: 25px 16px !important;
}

.ant-collapse-item {
  border-radius: 0px 0px 0px 0px !important;
}

.ant-collapse-content {
  border-top: none !important;
}

:root {
  --white: #ffffff;

  --gray-100: #f2f2f2;
  --gray-200: #d2d2d2;
  --gray-300: #8b8b8b;
  --black-700: #2b2b2b;

  --orange-700: #f04e23;
  --greem-400: #2fd270;
  --red-400: #e01e37;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 728px) {
  html {
    font-size: 87.5%;
  }
}

body {
  background: var(--gray-200);
  color: var(--black-700);
}

body,
input,
textarea,
select,
button {
  font: 400 1rem "Montserrat", sans-serif;
}

button {
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}
