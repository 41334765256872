.pageLoginRegistrationForm {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    background-color: var(--white);
    max-width: 596px;
    width: 100%;
    height: 411px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;

    h2 {
      margin-bottom: 2rem;
    }

    .pageLoginRegistrationFormInput {
      margin-bottom: 1.5rem;
      position: relative;
      width: 100%;
      input {
        width: 100%;
        background-color: var(--gray-100);
        outline: none;
        border: none;
        height: 51px;
        border-radius: 12px;
        padding-left: 3rem;
      }

      .pageLoginRegistrationInputIcon {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 1rem;
        height: 50%;
        width: 5%;
      }
    }

    button[type="submit"] {
      width: 100%;
      height: 51px;
      background-color: #f04e23;
      border-radius: 12px;
      border: none;
      outline: none;
      color: var(--white);
      font-weight: 600;
      margin-bottom: 1.5rem;
    }

    p {
      text-align: center;
      span {
        color: #f04e23;
        font-weight: 500;
      }
    }
  }
}
