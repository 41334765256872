.pageLogin {
  background: var(--gray-300);
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0.01)), url("../../assets/imgs/LOGIN-PLANO-NPAC-COMPUTADOR.png");
  background-size: cover;
  background-repeat: no-repeat;
  
  .pageLoginNavegation {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    flex-wrap: wrap;
    padding: 0px 30px;

    @media (max-width: 500px) {
      justify-content: center;
    }

    .pageLoginNavegationLogo {
      h4 {
        color: var(--white);
        font-weight: 600;
        font-size: 1.5rem;

        span {
          font-weight: bold;
        }
      }
    }

    .pageLoginNavegationListMenu {
      list-style: none;
      display: flex;

      li {
        margin: 0px 15px;
        color: var(--white);
        font-size: 1.35rem;
        font-weight: 800;
      }
    }
  }
}
 @media (max-width: 1200px) {
  .pageLogin {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.01)), url("../../assets/imgs/BANNER-LOGIN-PLANO-NPAC-CELULAR.png");
  }
 }
