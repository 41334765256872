.contentLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    padding: 1rem;
    border-radius: 0.35rem;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1);

    div {
        &:nth-child(1) {
            width: 35%;
        }

        &:nth-child(2) {
            width: 10%;
        }

        &:nth-child(3) {
            width: 20%;
        }

        &:nth-child(4) {
            width: 20%;
        }

        &:nth-last-child(1) {
            width: 5%;
        }
    }

    & + .contentLine {
        margin-top: 1rem;
    }

    &:hover {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
    }
}