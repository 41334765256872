.container {
  background-color: var(--white);
  padding: 1rem;
  border-radius: 0.25rem;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.title {
  font-weight: 600;
}

.icon {
  color: var(--gray-300);
  cursor: pointer;
  &:hover {
    color: var(--orange-700);
  }
}

.button {
  align-items: center;
  display: flex;
  gap: 0.5rem;
}

.form {
  margin-top: 1rem;
}

.body {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;
}

.label {
  margin-left: 0.1rem;
  align-self: flex-start;
}

.labelWithIcon {
  display: flex;
  gap: 0.5rem;
  margin-left: 0.1rem;
  align-items: center;
  cursor: text;
  align-self: flex-start;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.filtersRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.formGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.checkbox {
  margin-left: 0.5rem;
}

.tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
}

.boxCademi {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    &:first-child {
      font-size: 1.5rem;
      color: var(--gray-200);
    }
  }
}

.checkbox {
  margin-left: 0.5rem;
  font-size: 1.5rem;
  color: var(--gray-200);
}

.badge {
  background-color: var(--gray-100);
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.profileContainer {
  background-color: var(--gray-100);
  height: 11rem;
  width: 11rem;
  border-radius: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 0.5rem;
}

.profileContainerBg {
  background-color: var(--white);
  height: 10.5rem;
  width: 10.5rem;
  border-radius: 10.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.profile {
  object-fit: contain;
  height: 10rem;
  width: 10rem;
  border-radius: 10rem;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;

  input {
    outline: none;
    margin-bottom: 1rem;
    height: 50px;
    background-color: #fff;
  }
}

.modalTitle {
  font-weight: 600;
  font-size: 20px;
}

.editButton {
  border: none;
  cursor: pointer;
  color: #f04e23;
  background-color: transparent;
  margin-left: 5px;
}

.data {
  background-color: var(--gray-100);
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  word-break: break-all;
}

.rowModal {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.dataTitle {
  font-weight: 600;
  margin-right: 2px;
}

.scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

.avatarContainer {
  background-color: var(--gray-100);
  height: 4rem;
  width: 4rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.avatarContainerBg {
  background-color: var(--white);
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.avatar {
  object-fit: contain;
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
}
