.containerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--white);
    padding: 1.5rem;
    border-radius: 0.25rem;
}

.boxReport {
    width: 33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & + div {
        border-left: 2px solid var(--gray-100);
    }

    p {
        font-size: 1rem;
        font-weight: 700;
        color: var(--black-700);
        margin-top: 0.35rem;
        letter-spacing: 1px;
    }

    div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.35rem;

        cursor: pointer;
            transition: filter 0.2s;

        &:hover {
            filter: brightness(1.4);
        }

        span {
            font-weight: 500;
            font-size: 1.5rem;
            color: var(--orange-700);
        }

        svg {
            margin-left: 0.5rem;
            color: var(--orange-700);
        }
    }
}