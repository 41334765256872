.pageInvitationRegistration {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;   

    h3 {
      margin-top: 2rem;
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }
    
    ::-webkit-scrollbar-track {
        background-color: #f2f2f2;
      }
  
      ::-webkit-scrollbar {
        width: 6px;
        background: #f2f2f2;
      }
  
      ::-webkit-scrollbar-thumb {
        background: #d2d2d2;
      }
   
      .registerUserInvatation {
        overflow-y: auto; 
        height: 600px;   
        width: 100%; 
        padding: 2rem; 
      }
   
    form {
      background-color: var(--white);
      display: flex; 
      flex-direction: column;
      align-items: center;
      width: 100%; 
      max-width: 1000px; 
      padding-top: 1rem;

      .registerUserInvatationGroup {
        display: flex; 
        width: 100%; 
        justify-content: space-between; 
        margin-bottom: 0.5rem;
      }

      .eyeButton {
        background-color: transparent;
        border: none;
        cursor: pointer;
        position: relative;
        right: 3rem;
        top: 1rem;
        height: 50%;
        width: 0% !important;
      }
     
      h2 {
        font-size: 2rem;
        margin-bottom: 1rem;
      } 

      input {
        width: 100%;
        background-color: var(--gray-100);
        outline: none;
        border: none;
        height: 51px;
        border-radius: 12px;
        padding-left: 2rem;
       
      } 

      select {
            width: 100%;
            background-color: var(--gray-100);
            outline: none;
            border: none;
            height: 51px;
            border-radius: 12px;
            padding-left: 2rem;
          
      } 
      span{
        color: #f04e23;
        font-weight: 500;  
      }
    


      input[type="checkbox"] {
        height: 24px; 
        width: 24px;
        margin-bottom: 0;
        margin-right: 1rem;
      }
      
      .termsServices {
        display: flex; 
        align-items: center;  
        width: 100%; 
        margin-top: 2rem;
        margin-bottom: 2rem;
      }

      button[type="submit"] {
        width: 100%;
        height: 51px;
        background-color: #f04e23;
        border-radius: 12px;
        border: none;
        outline: none;
        color: var(--white);
        font-weight: 600; 

        &[disabled] {
          background-color: var(--gray-200);
          color: var(--gray-100);
          cursor: not-allowed;
        }
      }      
    }
  } 

   @media (min-width: 1000px) and (max-width: 1400px) { 
    .registerUserInvatation {
      height: 400px !important; 
      border-radius: 0 !important;
    }
  } 