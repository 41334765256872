.button {
  background-color: var(--orange-700);
  border-radius: 0.35rem;
  border: none;
  outline: none;
  color: var(--white);
  cursor: pointer;
  padding: 0.65rem 2rem;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
}
