.financialPage {
  display: grid;
  grid-gap: 15px 15px;
  grid-template: "financialBankData financialBankData financialBankData financialAccountAvailable" 500px "financialInvestments financialInvestments financialProfits financialProfits" 300px;
  width: 100%;

  .financialBankData {
    grid-area: financialBankData;
    background-color: var(--white);
    grid-column: 1 / 4;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem;
    width: 100%;

    h1 {
      font-size: 2rem;
    }

    .financialBankDataButton {
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 1rem;
      font-weight: bold;
      color: #f04e23;
      background-color: transparent;

      span {
        padding-left: 1rem;
      }
    }

    .financialBankDataBorder {
      border: solid 1px #f2f2f2;
      height: 100%;
    }

    .financialBankDataUser {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 2rem;
      height: 100%;

      border-bottom: solid 2px #f2f2f2;

      .financialBankDataUserItem {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;

        h3 {
          font-size: 1.5rem;
          margin-bottom: 0.8rem;
        }

        span {
          color: #8b8b8b;
        }
      }
    }

    .financialBankDataAccountUser {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding: 2rem;
      height: 100%;

      .financialBankDataUserItem {
        width: 100%;
        display: flex;
        flex-direction: column;
        text-align: center;

        h3 {
          font-size: 1.5rem;
          margin-bottom: 0.8rem;
        }

        span {
          color: #8b8b8b;
        }
      }
    }
  }

  .financialAccountAvailable {
    grid-area: financialAccountAvailable;
    background-color: var(--white);
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    padding: 2rem;

    .financialAccountAvailableTransfer {
      padding-top: 1rem;
      padding-bottom: 1.75rem;
    }

    .financialAccountAvailableItem {
      border-bottom: solid 2px #f2f2f2;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding-bottom: 2rem;
    }

    h2 {
      font-size: 1.85rem;
      max-width: 240px;
      width: 100%;
      margin-bottom: 1rem;
    }

    span {
      font-size: 2rem;
      color: #f04e23;
    }

    h3 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
    }

    p {
      color: #8b8b8b;
      max-width: 270px;
      width: 100%;
    }

    button {
      width: 100%;
      height: 50px;
      background: #f04e23;
      outline: none;
      text-align: center;
      justify-content: center;
      align-items: center;
      display: flex;
      outline: none;
      border-radius: 12px;
      border: none;
      color: #fff;
      font-weight: 600;
    }
  }

  .financialInvestments {
    grid-area: financialInvestments;
    background-color: var(--white);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    align-items: center;
    cursor: pointer;

    ::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }

    ::-webkit-scrollbar {
      width: 6px;
      background: #f2f2f2;
    }

    ::-webkit-scrollbar-thumb {
      background: #d2d2d2;
    }

    h3 {
      font-size: 2rem;
      padding-bottom: 1rem;
    }

    .financialSeeInvestment {
      height: 100%;
      background-color: #f2f2f2;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        color: #d2d2d2;
        font-size: 1.5rem;
        padding-left: 1rem;
      }
    }

    ul {
      width: 100%;
      list-style: none;
      overflow-y: scroll;
      padding: 1rem;

      li {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 1rem;
        border-bottom: 2px solid #f2f2f2;
        padding-bottom: 1rem;
      }

      .refunded {
        color: red;
        font-size: 0.8rem;
      }
      .processing {
        color: #f04e23;
        font-size: 0.8rem;
      }

      .canceled {
        color: red;
        font-size: 0.8rem;
      }

      .concluded {
        color: #2fd270;
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
      }

      .information {
        text-align: right;

        p {
          color: #8b8b8b;
          font-size: 0.8rem;
          padding-top: 5px;
        }
      }

      .values {
        p {
          color: #8b8b8b;
          font-size: 0.8rem;
          padding-top: 5px;
        }

        h6 {
          font-size: 1rem;
        }
      }
    }
  }

  .financialProfits {
    grid-area: financialProfits;
    background-color: var(--white);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    align-items: center;
    position: relative;
    cursor: pointer;

    ::-webkit-scrollbar-track {
      background-color: #f2f2f2;
    }

    ::-webkit-scrollbar {
      width: 6px;
      background: #f2f2f2;
    }

    ::-webkit-scrollbar-thumb {
      background: #d2d2d2;
    }

    h3 {
      font-size: 2rem;
      padding-bottom: 1rem;
    }

    .financialSeeProfits {
      height: 100%;
      background-color: #f2f2f2;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      span {
        color: #d2d2d2;
        font-size: 1.5rem;
        padding-left: 1rem;
      }
    }
    ul {
      width: 100%;
      list-style: none;
      overflow-y: scroll;
      padding: 1rem;

      li {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 1rem;
        border-bottom: 2px solid #f2f2f2;
        padding-bottom: 1rem;
      }
      .refunded {
        color: red;
        font-size: 0.8rem;
      }

      .processing {
        color: #f04e23;
        font-size: 0.8rem;
      }

      .concluded {
        color: #2fd270;
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
      }

      .information {
        text-align: right;

        p {
          color: #8b8b8b;
          font-size: 0.8rem;
          padding-top: 5px;
        }
      }

      .values {
        p {
          color: #8b8b8b;
          font-size: 0.8rem;
          padding-top: 5px;
        }

        h6 {
          font-size: 1rem;
        }
      }
    }
  }
}
.withdrawal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5%;
}

.vertical {
  padding: 20px;
  margin-left: 0.1%;
  border-left: 1px solid black;
  height: 50px;
}

.information {
  display: flex;
  flex-direction: column;
  margin-bottom: 1%;
  h5 {
    white-space: nowrap;
  }
  .icon {
    color: #f04e23;
    margin-left: 30%;
  }
}
.financialModalEdit {
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  input {
    outline: none;
    margin-bottom: 1rem;
    height: 50px;
  }

  select {
    margin-bottom: 1rem;
    height: 50px;
    outline: none;
    padding-right: 1rem;
  }

  .financialModalEditAccount {
    display: flex;

    input:nth-child(1) {
      width: 30%;
      margin-right: 1rem;
    }

    input:nth-child(3) {
      width: 30%;
      margin-left: 1rem;
    }

    text-align: center;

    padding-bottom: 1rem;
  }

  .financialModalEditKey {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;

    h4 {
      font-size: 1.5rem;
      margin-bottom: 2rem;
    }

    border-top: solid 2px #f2f2f2;
    padding-top: 2rem;

    button {
      height: 50px;

      background-color: #f04e23;
      border-radius: 12px;
      outline: none;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
      cursor: pointer;
      font-weight: bold;
      color: #fff;
    }

    .financialModalAddKeys {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 2rem;

      input {
        width: 83%;

        & + button {
          margin-left: 0.35rem;
        }
      }

      button {
        height: 50px;
        width: 50px;
        background-color: #f04e23;
        border-radius: 12px;
        outline: none;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
        font-weight: bold;
        color: #fff;

        & + button {
          margin-left: 0.35rem;
        }
      }
    }
  }
}

.financialStepsTransfer {
  display: flex;
  height: 40px;
  width: 100%;
  padding-top: 5px;
}

.textDateTransfer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  p {
    color: #8b8b8b;
    font-size: 0.9rem;
    padding-top: 5px;
  }
}

@media (max-width: 900px) {
  .financialPage {
    grid-template:
      "financialBankData financialBankData"
      "financialAccountAvailable financialAccountAvailable"
      "financialInvestments financialInvestments" 300px
      "financialProfits financialProfits" 300px;

    .financialBankData {
      padding: 1rem;

      h1 {
        font-size: 2rem;
        text-align: center;
      }

      .financialBankDataUser {
        flex-direction: column;
        padding: 1rem;

        .financialBankDataUserItem {
          padding: 1rem;
        }
      }

      .financialBankDataAccountUser {
        flex-direction: column;
        padding: 1rem;

        .financialBankDataUserItem {
          padding: 1rem;
        }
      }
    }

    .financialAccountAvailable {
      grid-column: 1/4;
    }

    .financialInvestments {
      padding: 1rem;
      grid-column: 1/4;

      span {
        padding-left: 0.5rem !important;
        font-size: 1.3rem !important;
      }
    }

    .financialProfits {
      padding: 1rem;
      grid-column: 1/4;

      span {
        padding-left: 0.5rem !important;
        font-size: 1.3rem !important;
      }
    }
  }
}

@media (min-width: 900px) and (max-width: 1300px) {
  .financialPage {
    grid-template:
      "financialBankData financialBankData"
      "financialAccountAvailable financialAccountAvailable"
      "financialInvestments financialInvestments" 300px
      "financialProfits financialProfits" 300px;

    .financialBankData {
      padding: 1rem;

      h1 {
        font-size: 2rem;
        text-align: center;
      }

      .financialBankDataUser {
        padding: 1rem;

        .financialBankDataUserItem {
          padding: 1rem;
        }
      }

      .financialBankDataAccountUser {
        padding: 1rem;

        .financialBankDataUserItem {
          padding: 1rem;
        }
      }
    }

    .financialAccountAvailable {
      grid-column: 1/4;
    }

    .financialInvestments {
      padding: 1rem;
      grid-column: 1/4;

      span {
        padding-left: 0.5rem !important;
        font-size: 1.3rem !important;
      }
    }

    .financialProfits {
      padding: 1rem;
      grid-column: 1/4;

      span {
        padding-left: 0.5rem !important;
        font-size: 1.3rem !important;
      }
    }
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .financialBankData {
    padding: 1rem !important;

    h3 {
      font-size: 1.5rem !important;
    }

    span {
      font-size: 0.8rem !important;
    }
  }
}
