.pageLoginRegistrationForm {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    background-color: rgba(255, 255, 255, 0.45);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(13.5px);
    -webkit-backdrop-filter: blur(13.5px);
    border-radius: 20px;
    border: 1.5px solid rgba(255, 255, 255, 0.28);
  }

  form {
    background-color: var(--white);
    background: rgba(255, 255, 255, 0.15);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5.2px);
    -webkit-backdrop-filter: blur(5.2px);
    border: 1px solid rgba(255, 255, 255, 0.15);
    max-width: 630px;
    width: 100%;
    height: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 2rem;
    padding-right: 2rem;

    h2 {
      margin-bottom: 2rem;
      color: #ffff;
    }

    .pageLoginRegistrationFormInput {
      margin-bottom: 1.5rem;
      position: relative;
      width: 100%;
      input {
        width: 100%;
        background: rgba(255, 255, 255, 0.65);
        outline: none;
        border: none;
        height: 51px;
        border-radius: 12px;
        padding-left: 3rem;
      }

      .pageLoginRegistrationInputIcon {
        position: absolute;
        top: 1rem;
        left: 1rem;
      }

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 1rem;
        top: 1rem;
        height: 50%;
        width: 5%;
      }
    }

    button[type="submit"] {
      width: 100%;
      height: 51px;
      background-color: rgba(240, 78, 35, 0.84);
      border-radius: 12px;
      border: none;
      outline: none;
      color: var(--white);
      font-weight: 600;
      margin-bottom: 1.5rem;
    }

    p {
      text-align: center;
      span {
        color: #f04e23;
        font-weight: 500;
      }
    }
  }
}
