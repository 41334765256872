.container {
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  background-color: var(--gray-100);
  border-radius: 0.35rem;
  border: none;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  color: var(--black-700);
}

.containerError {
  width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  background-color: var(--gray-100);
  border-radius: 0.35rem;
  border: none;
  border-color: red;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
  color: var(--black-700);
}
