.careerPage {
  display: grid;
  gap: 20px 20px;
  grid-template:
    "careerPageAgentData careerPageAgentData" 500px
    "careerPageAgentData careerPageAgentData"
    "careerPageCommonAgent careerPageAufssPersonal" 125px
    "careerPageCommonAgent careerPageAufssTotal" 125px;

  .notFound {
    background-color: var(--white);
    grid-area: careerPageAgentData;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centraliza verticalmente */
    justify-content: center; /* Centraliza horizontalmente */
    padding: 2rem;
    text-align: center;
    height: 98.6vh; /* Ocupa todo o height disponível */
  }
  .careerPageAgentData {
    background-color: var(--white);
    grid-area: careerPageAgentData;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    text-align: center;

    .careerPageAgentTitle {
      h2 {
        font-size: 2rem;
        font-weight: bold;
        color: #2b2b2b;
        margin-bottom: 8px;
      }

      span {
        color: #d2d2d2;
        font-size: 1rem;
      }

      margin-bottom: 2rem;
    }

    .careerPageAgentfront {
      display: flex;
      width: 100%;
      justify-content: space-between;
      padding-bottom: 2rem;

      .careerPageAgentfrontItem {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;

        h4 {
          font-size: 1.2rem;
          margin-bottom: 0.8rem;
          color: #2b2b2b;
        }

        ul {
          list-style: none;
        }

        li {
          color: #8b8b8b;
          margin-bottom: 0.8rem;

          span {
            color: #d2d2d2;
            margin-left: 0.5rem;
          }
        }
      }
    }

    .careerPageAgentfrontBorder {
      border: solid #f2f2f2 1px;
      height: 100%;
    }

    .careerPageAgentInformation {
      width: 100%;
      display: flex;
      border-top: solid 2px #f2f2f2;
      height: 100%;
      padding-top: 2rem;

      .careerPageAgentInformationItem {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        h5 {
          font-size: 4rem;
          font-weight: 500;
          color: #d2d2d2;
          margin-right: 1.5rem;
        }

        .careerPageAgentInformationDescriptionItem {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          span:nth-child(1) {
            color: #2b2b2b;
            font-weight: 600;
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
          }

          span:nth-child(2) {
            color: #f04e23;
            font-size: 1rem;
          }
        }
      }
    }
  }

  .careerPageCommonAgent {
    background-color: var(--white);
    grid-area: careerPageCommonAgent;
    border-radius: 8px;
    align-items: center;
    text-align: center;
    display: flex;
    padding: 2rem;
    flex-direction: column;
    width: 100%;

    .careerPageCommonAgentText {
      width: 100%;

      h4 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
      }

      h5 {
        font-size: 2.5rem;
        margin-bottom: 0.8rem;
        font-weight: 500;
        color: #8b8b8b;
      }

      span {
        color: #f04e23;
        font-size: 1.5rem;
      }

      padding-bottom: 1.5rem;
      border-bottom: solid 2px #f2f2f2;
    }

    p {
      padding-top: 1.5rem;
      color: #8b8b8b;
    }

    strong {
      color: #2b2b2b;
    }

    span {
      color: #f04e23;
    }
  }

  .careerPageAufssPersonal {
    background-color: var(--white);
    grid-area: careerPageAufssPersonal;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    h4 {
      font-size: 1.7rem;
      text-align: center;
    }

    span {
      color: #f04e23;
      font-weight: 500;
      margin-left: 1rem;
    }
  }

  .careerPageAufssTotal {
    background-color: var(--white);
    grid-area: careerPageAufssTotal;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;

    h4 {
      font-size: 1.7rem;
      text-align: center;
    }

    span {
      color: #f04e23;
      font-weight: 500;
      margin-left: 1rem;
    }

    button {
      position: absolute;
      height: 60px;
      width: 60px;
      background-color: #f04e23;
      border: none;
      outline: none;
      cursor: pointer;
      border-radius: 50%;
      background-image: url("../../assets/imgs/chatboxes.png");
      background-repeat: no-repeat;
      background-position: center;
      right: 15px;
      bottom: 15px;
    }
  }
}

@media (max-width: 1000px) {
  .careerPage {
    display: grid;
    gap: 20px 20px;
    grid-template:
      "careerPageAgentData careerPageAgentData" 500px
      "careerPageAgentData careerPageAgentData"
      "careerPageCommonAgent careerPageCommonAgent"
      "careerPageAufssPersonal careerPageAufssPersonal" 125px
      "careerPageAufssTotal careerPageAufssTotal" 125px;

    .careerPageAgentData {
      padding: 1rem;
      .careerPageAgentfront {
        flex-direction: column;
        padding-bottom: 0;

        .careerPageAgentfrontItem {
          padding-top: 1rem;
        }
      }

      .careerPageAgentInformation {
        flex-direction: column;
        padding-top: 0;

        .careerPageAgentInformationItem {
          flex-direction: column;
          width: 100%;
          padding-bottom: 1rem;
          h5 {
            margin-left: 0;
          }

          .careerPageAgentInformationDescriptionItem {
            align-items: center;
          }
        }
      }
    }

    .careerPageAufssTotal {
      button {
        background-color: red;
        width: 50px;
        height: 50px;
        right: 8px;
        bottom: 8px;
      }
    }
  }
}

@media (min-width: 1000px) and (max-width: 1300px) {
  .careerPage {
    display: grid;
    gap: 20px 20px;
    grid-template:
      "careerPageAgentData careerPageAgentData" 500px
      "careerPageAgentData careerPageAgentData"
      "careerPageCommonAgent careerPageCommonAgent"
      "careerPageAufssPersonal careerPageAufssPersonal" 125px
      "careerPageAufssTotal careerPageAufssTotal" 125px;

    .careerPageAgentData {
      .careerPageAgentfront {
        .careerPageAgentfrontItem {
          padding-top: 1rem;
        }
      }

      .careerPageAgentInformation {
        .careerPageAgentInformationItem {
          flex-direction: column;
          width: 100%;
          padding-bottom: 1rem;
          h5 {
            margin-left: 0;
          }

          .careerPageAgentInformationDescriptionItem {
            align-items: center;
          }
        }
      }
    }

    .careerPageAufssTotal {
      button {
        background-color: red;
        width: 50px;
        height: 50px;
        right: 8px;
        bottom: 8px;
      }
    }
  }
}
