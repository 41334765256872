.container {
  padding-top: 6.2rem;
}

.content {
  padding-left: 1rem;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  padding-right: 2.5rem;
  flex: 1;
  margin-left: 18.3rem;
}

.scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .content {
    margin-left: 1.5rem;
  }
}
