.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  min-width: 1000px;
}

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.hheadTable {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    width: 28%;

    p {
      color: var(--black-700);
      font-weight: 700;
    }

    &:nth-child(1) {
      width: 16%;
    }
  }

  .boxHeadName {
    width: 28%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    p {
      margin-right: 0.35rem;
    }
  }
}

.arrowDown {
  width: 0 !important;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 5px solid var(--black-700);
}

.boxButton {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 0 1rem 0;

  .contentButtonDownload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;

    p {
      text-wrap: nowrap;
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;
      text-wrap: nowrap;
      color: var(--orange-700);
      font-weight: 700;
      margin-left: 0.5rem;
      cursor: pointer;

      svg {
        margin-left: 0.5rem;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
