.container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: 100%;
    column-gap: 1rem;
    padding-top: 1rem;
}

.contentWhite {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--white);
    border-radius: 0.25rem;
    padding: 2rem;

    .title {
        font-size: 2rem;
        font-weight: 700;
        color: var(--black-700);
        margin-bottom: 1rem;
    }

    .description {
        font-size: 0.75rem;
        font-weight: 400;
        color: var(--gray-300);
        margin-bottom: 1rem;
    }

    .boxOthersReports {
        width: 100%;
        height: max-content;
        min-height: calc(100vh - 29.7rem);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 1.1rem;

        .report {
            display: flex;
            align-items: center;
            justify-content: center;

            & + .report {
                margin-top: 1rem;
            }

            span {
                font-size: 1.1rem;
                font-weight: 700;
                color: var(--black-700);
                margin-right: 10px;
            }

            p {
                display: flex;
                align-items: center;
                font-size: 1rem;
                font-weight: 400;
                color: var(--orange-700);

                svg {
                    margin-left: 5px;
                }
            }
        }
    }

    .boxFlow {
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;
        right: 0;
        left: initial;
    }
}
