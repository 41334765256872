.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.profileContainer {
  background-color: var(--gray-100);
  height: 11rem;
  width: 11rem;
  border-radius: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 1rem;
}

.profileContainerBg {
  background-color: var(--white);
  height: 10.5rem;
  width: 10.5rem;
  border-radius: 10.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.profile {
  object-fit: contain;
  height: 10rem;
  width: 10rem;
  border-radius: 10rem;
}

.userData {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
}

.userName {
  color: var(--black-700);
}

.userId {
  color: var(--gray-200);
}

.userStatus {
  color: var(--greem-400);
}

.navList {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.navItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--gray-300);
  font-weight: 500;
  cursor: pointer;
  &:hover {
    color: var(--orange-700);
  }
}

.activeItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  cursor: pointer;
  color: var(--orange-700);
}

.rowButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.orangeButton {
  font-weight: 600;
  color: var(--orange-700);
  border-style: solid;
  padding: 0.25rem;
  border-width: 0.1rem;
  cursor: pointer;
}

.grayButton {
  font-weight: 500;
  color: var(--gray-200);
  border-style: solid;
  padding: 0.25rem;
  border-width: 0.1rem;
  cursor: pointer;
}

.icon {
  color: var(--gray-300);
  cursor: pointer;
  &:hover {
    color: var(--orange-700);
  }
}

.divider {
  height: 1.25rem;
  width: 2px;
  background-color: var(--gray-100);
}
