.dividerHorizontal {
    width: 100%;
    height: 2px;
    background-color: var(--gray-100);
}

.dividerVertical {
    width: 2px;
    height: 100%;
    background-color: var(--gray-100);
}